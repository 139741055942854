import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { LRUCache } from "lru-cache";
import io from "socket.io-client";

let ipInfoUser = {};
const cache = new LRUCache({
  max: 100,
  maxAge: 1000 * 60 * 5, // 5 minutes
});

const abortControllers = {};

const getAbortController = (key) => {
  if (abortControllers[key]) {
    abortControllers[key].abort();
  }
  abortControllers[key] = new AbortController();
  return abortControllers[key];
};

const getUserInfo = async () => {
  try {
    if (Object.keys(ipInfoUser).length > 0) return ipInfoUser;

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), 1000)
    );

    const ipInfoPromise = axios.get(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
    );

    const response = await Promise.race([timeoutPromise, ipInfoPromise]);

    ipInfoUser = response.data;

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    return {};
  }
};

const storeToken = (token) => {
  localStorage.setItem("token", token);
};

const removeToken = () => {
  localStorage.removeItem("token");
};

const setAuthHeader = () => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const renewToken = async () => {
  try {
    const userInfo = await getUserInfo();

    const batteryLevel = updateBatteryLevel();

    setAuthHeader();

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}user/renewToken`,
      withCredentials: true,
      headers: {
        "X-User-Info": JSON.stringify(userInfo),
        "X-Battery-Level": batteryLevel,
      },
    });

    if (response.data.errors) {
      console.error("Error renewing token:", response.data.errors);
    } else {
      if (response.headers.authorization) {
        const token = response.headers.authorization.split(" ")[1];
        storeToken(token);

        return token;
      } else {
        console.error("Error renewing token: no token in response");
      }
    }
  } catch (error) {
    console.error("Error renewing token:", error);
  }
};

const isTokenExpired = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(token); // assuming you are using JWT tokens
    const currentTime = new Date().getTime() / 1000; // convert to seconds

    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

const renewTokenIfNeeded = async () => {
  if (!isTokenExpired()) {
    return;
  }

  try {
    await renewToken();
  } catch (error) {
    console.error("Error renewing token:", error);
  }
};

const connectSocketWithToken = async () => {
  try {
    await renewTokenIfNeeded();

    const token = localStorage.getItem("token");
    const badgerId = localStorage.getItem("badgerId");
    const decodedToken = jwtDecode(token);

    const socket = io(process.env.REACT_APP_API_URL_SOCKET, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      },
    });

    socket.on("connect", () => {
      socket.emit("joinRoom", {
        userId: decodedToken.userId,
        badgerId: badgerId || "",
      });
    });

    return socket;
  } catch (error) {
    console.error("Error connecting Socket.IO:", error);
  }
};

const renewTokenIfNeededAndConnectSocket = async () => {
  try {
    await renewTokenIfNeeded();
    return await connectSocketWithToken();
  } catch (error) {
    console.error("Error renewing token and connecting Socket.IO:", error);
  }
};

const updateBatteryLevel = () => {
  const batteryLevel = localStorage.getItem("battery");
  if (batteryLevel) {
    return batteryLevel;
  }
  return null;
};

const makeRequest = async (url, method, data, resolve, reject) => {
  try {
    const cacheKey = `${method}_${url}_${JSON.stringify(data)}`;
    console.log("cacheKey", cacheKey);
    const cachedData = cache.get(cacheKey);

    const controller = getAbortController(method + url);

    if (cachedData) {
      resolve(cachedData);
      return;
    }

    await renewTokenIfNeeded();

    const userInfo = await getUserInfo();
    const batteryLevel = updateBatteryLevel();
    setAuthHeader();

    const axiosOptions = {
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      withCredentials: true,
      headers: {
        "X-User-Info": JSON.stringify(userInfo),
        "X-Battery-Level": batteryLevel,
      },
      data,
      signal: controller.signal,
    };

    if (method.toUpperCase() === "GET") {
      axiosOptions.params = data;
    } else {
      axiosOptions.data = data;
    }

    const response = await axios(axiosOptions);

    if (response.data.errors) {
      reject(response.data.errors);
    } else {
      if (response.headers.authorization) {
        const token = response.headers.authorization.split(" ")[1];
        storeToken(token);
      }

      if (
        !cacheKey.startsWith("get_mission/exports") &&
        !cacheKey.startsWith("get_mission/export") &&
        !cacheKey.startsWith("post_mission/updateTempWorkerToken") &&
        !cacheKey.startsWith("get_activity/list") &&
        !cacheKey.startsWith("post_bigCompany/updateBreaks") &&
        !cacheKey.startsWith("get_bigCompany/getBreaks") &&
        !cacheKey.startsWith("post_timeSheet/update")
      )
        cache.set(cacheKey, response.data);

      resolve(response.data);
    }
  } catch (error) {
    // if status code is 401, remove token and redirect to login page
    if (error.response && error.response.status === 401) {
      removeToken();
      window.location.href = process.env.REACT_APP_LOGIN;
    }
    reject(error);
  }
};

const ApiDatabase = {
  getUserInfo: (data, resolve, reject) => {
    makeRequest("user/info", "post", data, resolve, reject);
  },
  postImportMissions: (data, resolve, reject) => {
    makeRequest("mission/import", "post", data, resolve, reject);
  },
  getMissions: (data, resolve, reject) => {
    makeRequest(`mission/list`, "get", data, resolve, reject);
  },
  getAllMissions: (data, resolve, reject) => {
    makeRequest(`mission/allList`, "get", data, resolve, reject);
  },
  getMissionById: (data, resolve, reject) => {
    makeRequest(`mission/info/${data.id}`, "get", data, resolve, reject);
  },
  getCompanies: (data, resolve, reject) => {
    makeRequest("company/list", "get", data, resolve, reject);
  },
  getUserMissionById: (data, resolve, reject) => {
    makeRequest(`mission/user/${data.id}`, "get", data, resolve, reject);
  },
  getTimeSheet: (data, resolve, reject) => {
    makeRequest(`timeSheet/${data.id}`, "get", data, resolve, reject);
  },
  postCreateTimeSheetMission: (data, resolve, reject) => {
    makeRequest("timeSheet/create", "post", data, resolve, reject);
  },
  postUpdateTimeSheet: (data, resolve, reject) => {
    makeRequest("timeSheet/update", "post", data, resolve, reject);
  },
  postUpdateTimeCut: (data, resolve, reject) => {
    makeRequest("bigCompany/updateTimeCut", "post", data, resolve, reject);
  },
  getTimeCut: (data, resolve, reject) => {
    makeRequest("bigCompany/getTimeCut", "get", data, resolve, reject);
  },
  getNbInSettings: (data, resolve, reject) => {
    makeRequest("bigCompany/getNbInSettings", "get", data, resolve, reject);
  },
  getTeams: (data, resolve, reject) => {
    makeRequest("bigCompany/getTeams", "get", data, resolve, reject);
  },
  postUserInvite: (data, resolve, reject) => {
    makeRequest("bigCompany/inviteEmployee", "post", data, resolve, reject);
  },
  getCompaniesInfosEmployees: (data, resolve, reject) => {
    makeRequest(
      "company/companiesInfos/employees",
      "get",
      data,
      resolve,
      reject
    );
  },
  putCompaniesInfosEmployees: (data, resolve, reject) => {
    makeRequest(
      "company/companiesInfos/employees",
      "put",
      data,
      resolve,
      reject
    );
  },
  getCompaniesMissions: (data, resolve, reject) => {
    makeRequest("mission/companies", "get", data, resolve, reject);
  },
  getExportMissions: (data, resolve, reject) => {
    makeRequest(`mission/exports`, "get", data, resolve, reject);
  },
  getExportMission: (data, resolve, reject) => {
    makeRequest(
      `mission/export/${data.idMission}`,
      "get",
      data,
      resolve,
      reject
    );
  },
  updateTempWorkerPhone: (data, resolve, reject) => {
    makeRequest("mission/updateTempWorkerPhone", "post", data, resolve, reject);
  },
  getTeamInfos: (data, resolve, reject) => {
    makeRequest("user/teamInfo", "post", data, resolve, reject);
  },
  postTeamModify: (data, resolve, reject) => {
    makeRequest("user/modifyTeam", "post", data, resolve, reject);
  },
  deleteTeamMember: (data, resolve, reject) => {
    makeRequest(
      "bigCompany/employee/deleteEmployee",
      "post",
      data,
      resolve,
      reject
    );
  },
  getInterimAgencyMissions: (data, resolve, reject) => {
    makeRequest("mission/interimAgencies", "get", data, resolve, reject);
  },
  getInterimSectorMissions: (data, resolve, reject) => {
    makeRequest("mission/interimSectors", "get", data, resolve, reject);
  },
  updateTempWorkerToken: (data, resolve, reject) => {
    makeRequest("mission/updateTempWorkerToken", "post", data, resolve, reject);
  },
  getActivities: (data, resolve, reject) => {
    makeRequest("activity/list", "get", data, resolve, reject);
  },
  getAllInterimAgencies: (data, resolve, reject) => {
    makeRequest(
      `interimAgency/list?type=${data.type}`,
      "get",
      data,
      resolve,
      reject
    );
  },
  getBreaks: (data, resolve, reject) => {
    makeRequest(`bigCompany/getBreaks`, "get", data, resolve, reject);
  },
  postUpdateBreaks: (data, resolve, reject) => {
    makeRequest(`bigCompany/updateBreaks`, "post", data, resolve, reject);
  },
  renewTokenIfNeededAndConnectSocket,
  getCache: () => cache,
};

export default ApiDatabase;
