// MissionWeekSelector.jsx
import React from "react";
import { ChevronLeft, ChevronRight } from "../../assets/icons";
import MissionTimeSheet from "./MissionTimeSheet";

const MissionWeekSelector = ({
  isWaitingMission,
  missionByWeek,
  currentWeekIndex,
  currentWeekMissions,
  canGoPrev,
  canGoNext,
  goPrevWeek,
  goNextWeek,
  getWeekLabel,
  todayRef,
  timeCut,
}) => {
  if (isWaitingMission) {
    return (
      <>
        {[1, 2, 3, 4, 5].map((i) => (
          <MissionTimeSheet key={i} isWaiting={true} />
        ))}
      </>
    );
  }

  if (!missionByWeek?.length) {
    return (
      <div className="flex flex-col gap-4 items-center justify-center">
        <h1 className="text-2xl font-bold">Mission introuvable</h1>
        <p className="text-center">
          La mission que vous cherchez est introuvable.
        </p>
      </div>
    );
  }

  return (
    <>
      {/* Week selector */}
      <div className="flex gap-1 items-center py-2 px-4 border rounded w-fit">
        <button
          className={`cursor-pointer ${canGoPrev ? "" : "opacity-50"}`}
          aria-label="Chevron left"
          onClick={goPrevWeek}
          disabled={!canGoPrev}
          name="header_mission_week_selector_prev_date"
        >
          <ChevronLeft wh={20} color={canGoPrev ? "#374151" : "#9CA3AF"} />
        </button>
        <div>{getWeekLabel()}</div>
        <button
          className={`cursor-pointer ${canGoNext ? "" : "opacity-50"}`}
          aria-label="Chevron right"
          onClick={goNextWeek}
          disabled={!canGoNext}
          name="header_mission_week_selector_next_date"
        >
          <ChevronRight wh={20} color={canGoNext ? "#374151" : "#9CA3AF"} />
        </button>
      </div>

      {/* List of time sheets for the current week */}
      {currentWeekMissions.map((item, index) => {
        return (
          <MissionTimeSheet
            key={index}
            mission={item}
            timeCut={timeCut}
            todayRef={todayRef}
          />
        );
      })}
    </>
  );
};

export default MissionWeekSelector;
