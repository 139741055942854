import React from "react";
import { InformationCircle } from "../../assets/icons";
import { dateShortText } from "../../utils/Date";
import { floatToTime } from "../../utils/Time";
import Tooltip from "../tooltips/Tooltips";

const MissionDetailSidebar = ({
  missionDetail,
  currentWeekMissions,
  getTotalWorkedHours,
  getTotalNightHours,
  getTotalOvertimes,
}) => {
  if (!missionDetail) return null;

  // On vérifie s'il y a des missions pour cette semaine
  const hasMissionsThisWeek =
    currentWeekMissions && currentWeekMissions.length > 0;

  return (
    <div className="flex flex-1 max-w-[450px]">
      <div className="flex flex-col gap-8 w-full h-fit sticky top-0">
        {/* Information block */}
        <div className="flex w-full h-fit p-4 border border-gray-100 rounded-lg mission-detail-sidebar-infos">
          <div className="flex flex-col gap-8 w-full">
            <div className="font-black">Informations</div>
            <div className="flex justify-between items-center text-sm">
              <div className="font-black">Période</div>
              <div className="text-gray-500">
                {missionDetail?.dates?.start
                  ? dateShortText(new Date(missionDetail?.dates?.start))
                  : null}{" "}
                {">"}{" "}
                {missionDetail?.dates?.end
                  ? dateShortText(new Date(missionDetail?.dates?.end))
                  : null}
              </div>
            </div>
            <div className="flex justify-between items-center text-sm">
              <Tooltip
                placement="top"
                text="L'intérimaire disposera d'un accès au code jusqu'à cette date."
              >
                <div className="font-black flex gap-1 items-center underline decoration-dashed">
                  <div>Fin de souplesse estimée</div>
                  <div>
                    <InformationCircle wh={16} color="#374151" />
                  </div>
                </div>
              </Tooltip>
              <div className="text-gray-500">
                {missionDetail?.flexibility?.end
                  ? dateShortText(new Date(missionDetail?.flexibility?.end))
                  : null}
              </div>
            </div>
          </div>
        </div>

        {/* Weekly details block */}
        {hasMissionsThisWeek && (
          <div className="flex w-full h-fit p-4 border border-gray-100 rounded-lg mission-detail-sidebar-weekly-details">
            <div className="flex flex-col gap-8 w-full">
              <div className="font-black">Détail sur la semaine</div>
              <div className="flex justify-between items-center text-sm">
                <div className="font-black">Heures travaillées totales</div>
                <div className="text-gray-500">
                  {floatToTime(getTotalWorkedHours())}
                </div>
              </div>
              <div className="flex justify-between items-center text-sm">
                <div className="font-black">Heures de nuit totales</div>
                <div className="text-gray-500">
                  {floatToTime(getTotalNightHours())}
                </div>
              </div>
              <div className="flex justify-between items-center text-sm">
                <div className="font-black">Heures supplémentaires</div>
                <div className="text-gray-500">
                  {floatToTime(getTotalOvertimes())}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissionDetailSidebar;
