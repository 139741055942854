import React from "react";

const SlideOverBody = ({ className, children }) => {
  return (
    <div className={`relative p-6 flex-1 overflow-y-auto ${className}`}>
      {children}
    </div>
  );
};

export default SlideOverBody;
