import React from "react";
import { CrossIcon } from "../../../assets/icons";
import "../../../styles/main.scss";

const SlideOverHeader = ({ children, hide, className, hideShow }) => {
  return (
    <>
      <div className={`flex justify-between p-5 gap-5 ${className}`}>
        <h3 className="ft-lg tab:ft-2xl text-[#202124] font-semibold my-auto">
          {children}
        </h3>
        <button
          type={"button"}
          className="my-auto flex items-center justify-center border rounded p-1"
          onClick={hide}
        >
          <CrossIcon wh={20} color={"#111827"} />
        </button>
      </div>
    </>
  );
};

export default SlideOverHeader;
