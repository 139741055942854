import React from "react";

const SlideOverFooter = ({ className, children }) => {
  return (
    <>
      <div
        className={`flex items-center justify-end p-6 rounded-b gap-4 ${className}`}
      >
        {children}
      </div>
    </>
  );
};

export default SlideOverFooter;
