import React from "react";

const Button = ({ color, size, children, disabled, onClick, name }) => {
  const BUTTON_COLOR = {
    PRIMARY: "bg-workfully-700 text-white rounded-md sh-sm",
    SECONDARY:
      "bg-white text-gray-700 border b-white rounded-md border-gray-100 active:bg-gray-100",
    DEFAULT: "bg-transparent text-gray-700",
    DISABLED: "bg-gray-50 text-gray-400 border border-gray-300 rounded",
    ICON: "bg-transparent flex items-center justify-center rounded-md",
  };

  const BUTTON_SIZE = {
    SM: "h-9 ft-sm py-2 px-4",
    SMI: "h-9 ft-sm p-2",
    LG: "h-12 ft-lg py-3 px-6",
    LGI: "h-9 ft-sm p-3",
    FULL: "w-full h-12 ft-lg py-3 px-6",
    ICON: "h-9 w-9",
  };
  const colorClassName = BUTTON_COLOR[color || "PRIMARY"];
  const sizeClassName = BUTTON_SIZE[size || "SM"];

  return (
    <button
      type={"button"}
      name={name}
      className={
        "flex flex-row justify-center items-center gap-2 hover:shadow-md active:bg-gray-100 " +
        sizeClassName +
        " " +
        colorClassName
      }
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
