//display date in this format : Jeudi 25 Janvier 2024
module.exports.dateLong = (date, gmt = "UTC") => {
  return date.toLocaleDateString("fr-FR", {
    timeZone: gmt,
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

//display date in this format : 25 Janvier
module.exports.dateShortText = (date, gmt = "UTC") => {
  const options = {
    timeZone: gmt,
    day: "2-digit",
    month: "long",
  };

  const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

//display date in this format : 25 Janv.
module.exports.dateShortText2 = (date, gmt = "UTC") => {
  const options = {
    timeZone: gmt,
    day: "2-digit",
    month: "short",
  };

  const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

//display date in this format : 2024-02-29 09:00
module.exports.dateShortHours = (date, separator = "-") => {
  const formattedDate = new Date(date);

  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getDate().toString().padStart(2, "0");
  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");

  return `${year}${separator}${month}${separator}${day} ${hours}:${minutes}`;
};

//display date in this format : JJ-MM
module.exports.dateShort = (date) => {
  const formattedDate = new Date(date);

  const day = formattedDate.getDate().toString().padStart(2, "0");
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");

  return `${day}-${month}`;
};

//display in this format : JJ-MM HH:MM
module.exports.dateShortHoursWithoutYear = (date, separator = "-") => {
  const formattedDate = new Date(date);

  const day = formattedDate.getDate().toString().padStart(2, "0");
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");

  return `${day}${separator}${month} ${hours}:${minutes}`;
};

//check date validity
module.exports.dateStringIsValid = (dateString) => {
  let dateStr = dateString;

  //detect if birthDate has this regex /^..-..-....$/ or /^..\/..\/....$/ (xx-xx-xxxx or xx/xx/xxxx)
  if (dateStr.match(/^..-..-....$/) || dateStr.match(/^..\/..\/....$/)) {
    //detect if - or / in date
    if (dateStr.includes("-")) {
      const dateParts = dateStr.split("-");
      dateStr = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    } else {
      const dateParts = dateStr.split("/");
      dateStr = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    }
  }
  const date = new Date(dateStr);
  return date instanceof Date && !isNaN(date);
};

module.exports.formatTimeDifference = (timeDifferenceDate) => {
  const years = timeDifferenceDate.getUTCFullYear() - 1970;
  const months = timeDifferenceDate.getUTCMonth();
  const days = timeDifferenceDate.getUTCDate() - 1; // Moins 1 car getUTCDate renvoie le jour du mois (1-31)
  const hours = timeDifferenceDate.getUTCHours();
  const minutes = timeDifferenceDate.getUTCMinutes();
  const seconds = timeDifferenceDate.getUTCSeconds();

  if (years > 0) {
    return `${years} an${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `${months} moi${months > 1 ? "s" : ""}`;
  } else if (days > 0) {
    return `${days} jour${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} heure${hours > 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else {
    return `${seconds} seconde${seconds > 1 ? "s" : ""}`;
  }
};

module.exports.dateToEpoch = (date) => {
  const dateWithoutTime = this.dateWithoutTime(date);
  const userTimezoneOffset = dateWithoutTime.getTimezoneOffset() * 60000;
  return new Date(dateWithoutTime.getTime() - userTimezoneOffset);
};

module.exports.dateWithoutTime = (date) => {
  //if real date
  if (date instanceof Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  } else {
    //if epoch
    const dateF = new Date(date);
    return new Date(dateF.getFullYear(), dateF.getMonth(), dateF.getDate());
  }
};

//convert date 2024-08-01T00:00:00.000Z to 2024-08-01T00:00:00.000Z + current timezone offset
module.exports.forceDateToUtc0 = (dateString) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};
