import { useEffect, useState } from "react";
import { CrossIcon, Plus } from "../../../../../assets/icons";
import UseContext from "../../../../../hooks/useContext";
import ApiDatabase from "../../../../../server";
import { dateLong } from "../../../../../utils/Date";
import Button from "../../../../button";
import EmptyState from "../../../../EmptyState/EmptyState";
import StyledInput from "../../../../styledInput/StyledInput";
import SlideOverBody from "../../../component/body";
import SlideOverFooter from "../../../component/footer";
import SlideOverHeader from "../../../component/header";
import useSlideOver from "../../../useSlideOver";

const SlideOverUpdateTimeSheet = () => {
  const { slideOverData, setSlideOverDataReturn } = UseContext();
  const { idTimeSheet, idMission, dateMission } = slideOverData;
  const { toggleSlideOver } = useSlideOver();
  const [timeSheet, setTimeSheet] = useState([]);
  const [errorTimeSheet, setErrorTimeSheet] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeSlideOver = () => {
    toggleSlideOver();
  };

  useEffect(() => {
    if (idTimeSheet) {
      setIsWaiting(true);
      ApiDatabase.getTimeSheet(
        { id: idTimeSheet },
        (data) => {
          if (data.timeSheet?.periods.length) {
            data.timeSheet?.periods.sort(
              (a, b) => a.hours.position - b.hours.position
            );
            for (let i = 0; i < data.timeSheet?.periods.length; i++) {
              const period = data.timeSheet?.periods[i];
              setTimeSheet((prev) => {
                let temp = [...prev];
                temp[i] = {
                  start: period.hours.start,
                  end: period.hours.end,
                };
                return temp;
              });
              setErrorTimeSheet((prev) => {
                let temp = [...prev];
                temp[i] = { start: "", end: "" };
                return temp;
              });
            }
          }
          setIsWaiting(false);
        },
        (err) => {
          console.log("err", err);
          setIsWaiting(false);
        }
      );
    }
  }, [idTimeSheet]);

  const isStartBeforeEnd = (start, end) => {
    return (
      new Date(`1970-01-01T${start}`).getTime() <
      new Date(`1970-01-01T${end}`).getTime()
    );
  };

  useEffect(() => {
    const errors = [];
    for (let i = 0; i < timeSheet.length; i++) {
      const { start, end } = timeSheet[i];
      const nextPeriod = timeSheet[i + 1];
      if (start && end && !isStartBeforeEnd(start, end)) {
        errors[i] = { start: "Le début doit être avant la fin" };
      } else {
        errors[i] = { start: "", end: "" };
      }
      if (
        nextPeriod &&
        nextPeriod.start &&
        !isStartBeforeEnd(end, nextPeriod.start)
      ) {
        errors[i].end = "Les heures ne sont pas dans l'ordre";
        if (!errors[i + 1]) errors[i + 1] = { start: "", end: "" };
        errors[i + 1].start = "Les heures ne sont pas dans l'ordre";
      }
    }
    setErrorTimeSheet(errors);
  }, [timeSheet]);

  const addPeriod = () => {
    if (timeSheet.length < 5) {
      setTimeSheet((prev) => {
        return [...prev, { start: "", end: "" }];
      });
      setErrorTimeSheet((prev) => {
        return [...prev, { start: "", end: "" }];
      });
    }
  };

  const removePeriod = (index) => {
    setTimeSheet((prev) => {
      let temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
    setErrorTimeSheet((prev) => {
      let temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  const handleTimeInputBlur = () => {
    setTimeout(() => {
      const activeEl = document.activeElement;
      if (
        activeEl &&
        activeEl.tagName === "INPUT" &&
        activeEl.type === "time" &&
        activeEl.value === ""
      ) {
        return;
      }
      let times = [];
      timeSheet.forEach((period) => {
        if (period.start) times.push(period.start);
        if (period.end) times.push(period.end);
      });
      times.sort((a, b) => a.localeCompare(b));
      const newPeriods = [];
      for (let i = 0; i < times.length; i += 2) {
        newPeriods.push({
          start: times[i],
          end: times[i + 1] || "",
        });
      }
      setTimeSheet(newPeriods);
      setErrorTimeSheet(newPeriods.map(() => ({ start: "", end: "" })));
    }, 200);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (!idTimeSheet) {
      if (
        timeSheet.length === 0 ||
        errorTimeSheet.some((period) => period.start || period.end) ||
        timeSheet.some((period) => !period.start)
      ) {
        setIsLoading(false);
        return;
      }
      ApiDatabase.postCreateTimeSheetMission(
        { idMission, date: dateMission, timeSheet },
        (data) => {
          setIsLoading(false);
          setSlideOverDataReturn({
            idMission,
            date: dateMission,
            idTimeSheet: data.idTimeSheet,
            timeSheet: data.timeSheet,
            pause: data.pause,
            totalHours: data.totalHours,
            "totalHoursNight5%": data["totalHoursNight5%"],
            "totalHoursNight20%": data["totalHoursNight20%"],
          });
          closeSlideOver();
        },
        (err) => {
          setIsLoading(false);
          console.log(err);
        }
      );
    } else {
      ApiDatabase.postUpdateTimeSheet(
        { idTimeSheet, timeSheet },
        (data) => {
          setIsLoading(false);
          setSlideOverDataReturn({
            idMission,
            date: dateMission,
            timeSheet: data.timeSheet,
            pause: data.pause,
            totalHours: data.totalHours,
            "totalHoursNight5%": data["totalHoursNight5%"],
            "totalHoursNight20%": data["totalHoursNight20%"],
          });
          closeSlideOver();
        },
        (err) => {
          setIsLoading(false);
          console.log(err);
        }
      );
    }
  };

  return (
    <>
      <SlideOverHeader
        className={"slide-over-update-time-sheet-header"}
        hide={closeSlideOver}
        hideShow={true}
      >
        <div className={"capitalize"}>{dateLong(new Date(dateMission))}</div>
        <div className={"text-sm text-gray-500"}>
          Modifiez les heures pointées par les profils.
        </div>
      </SlideOverHeader>
      <SlideOverBody className={"slide-over-update-time-sheet-body"}>
        <div className={"flex flex-col gap-4 h-full"}>
          {isWaiting ? (
            <>
              <div
                className={
                  "flex justify-between items-center gap-2.5 animate-pulse"
                }
              >
                <div className={"h-4 bg-gray-200 rounded w-20"}></div>
                <div className={"h-6 bg-gray-200 rounded w-60"}></div>
              </div>
              <div className={"h-0.5 bg-gray-50 w-full"}></div>
              <div
                className={
                  "flex justify-between items-center gap-2.5 animate-pulse"
                }
              >
                <div className={"h-4 bg-gray-200 rounded w-20"}></div>
                <div className={"h-6 bg-gray-200 rounded w-60"}></div>
              </div>
            </>
          ) : (
            <>
              {timeSheet.length === 0 ? (
                <div
                  className={"flex flex-col justify-center items-center h-full"}
                >
                  <EmptyState
                    type={"mission_time_sheet_period"}
                    custom={
                      <div className={"w-fit my-6"}>
                        <Button
                          size={"SM"}
                          color={"SECONDARY"}
                          name={"slide-over-update-time-sheet-body-add-periods"}
                          onClick={() => addPeriod()}
                        >
                          <div className={"flex gap-1 items-center"}>
                            <div>
                              <Plus wh={20} color={"#374151"} />{" "}
                            </div>
                            <div>Ajouter un pointage</div>
                          </div>
                        </Button>
                      </div>
                    }
                  />
                </div>
              ) : (
                <>
                  {timeSheet.map((period, index) => (
                    <div key={index} className={"flex flex-col gap-3"}>
                      <div className={"flex gap-2 items-center"}>
                        <div className={"h-0.5 bg-gray-50 w-full"}></div>
                        <div className={"flex justify-end"}>
                          <Button
                            size={"ICON"}
                            color={"ICON"}
                            name={
                              "slide-over-update-time-sheet-body-delete-period"
                            }
                            onClick={() => removePeriod(index)}
                          >
                            <CrossIcon wh={20} color={"#374151"} />
                          </Button>
                        </div>
                      </div>
                      <div
                        className={"flex justify-between items-center gap-2.5"}
                      >
                        <div>Entrée</div>
                        <div className={"w-full max-w-64"}>
                          <StyledInput
                            type={"time"}
                            value={period?.start}
                            onChange={(e) => {
                              setTimeSheet((prev) => {
                                let temp = [...prev];
                                temp[index].start = e.target.value;
                                return temp;
                              });
                            }}
                            onBlur={handleTimeInputBlur}
                            error={errorTimeSheet[index]?.start}
                          />
                        </div>
                      </div>
                      <div className={"h-0.5 bg-gray-50 w-full"}></div>
                      <div
                        className={"flex justify-between items-center gap-2.5"}
                      >
                        <div>Sortie</div>
                        <div className={"w-full max-w-64"}>
                          <StyledInput
                            type={"time"}
                            value={period?.end}
                            onChange={(e) => {
                              setTimeSheet((prev) => {
                                let temp = [...prev];
                                temp[index].end = e.target.value;
                                return temp;
                              });
                            }}
                            onBlur={handleTimeInputBlur}
                            error={errorTimeSheet[index]?.end}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className={"w-fit"}>
                    <Button
                      size={"SM"}
                      color={"SECONDARY"}
                      name={"slide-over-update-time-sheet-body-add-period"}
                      onClick={() => addPeriod()}
                    >
                      <div className={"flex gap-1 items-center"}>
                        <div>
                          <Plus wh={20} color={"#374151"} />{" "}
                        </div>
                        <div>Ajouter</div>
                      </div>
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </SlideOverBody>
      <SlideOverFooter className={"slide-over-update-time-sheet-footer"}>
        <Button size={"SM"} color={"SECONDARY"} onClick={closeSlideOver}>
          Fermer
        </Button>
        {errorTimeSheet.some((period) => period.start || period.end) ? (
          <Button size={"SM"} color={"DISABLED"}>
            Confirmer
          </Button>
        ) : (
          <>
            {isLoading ? (
              <Button size={"SM"} color={"DISABLED"}>
                Confirmation...
              </Button>
            ) : (
              <Button
                size={"SM"}
                color={"PRIMARY"}
                name={"slide-over-update-time-sheet-footer-confirm"}
                onClick={handleSubmit}
              >
                Confirmer
              </Button>
            )}
          </>
        )}
      </SlideOverFooter>
    </>
  );
};

export default SlideOverUpdateTimeSheet;
