import React from "react";
import { Pencil, Synchronisation, WarningCircle } from "../../assets/icons";
import Button from "../button";

const MissionInfoBar = ({
  id,
  isWaitingUserMission,
  userMission,
  editTempWorkerPhone,
  editTempWorkerToken,
}) => {
  if (isWaitingUserMission) {
    return (
      <div className="flex gap-4 px-4 py-2 border border-gray-100 rounded-lg bg-white animate-pulse">
        <div className="flex flex-col w-full">
          <div className="flex gap-1 items-center font-bold">
            <div>Mission</div>
            <WarningCircle wh={20} color="#374151" />
          </div>
          <div className="flex">
            <div className={"h-4 bg-gray-200 rounded w-32"}></div>
          </div>
        </div>
        <div className="h-full w-0.5 bg-gray-100"></div>

        {/* Pin code */}
        <div className="flex justify-between gap-1 w-full items-center">
          <div className="flex flex-col">
            <div className="flex gap-1 items-center font-bold">
              <div>Code pin</div>
              <WarningCircle wh={20} color="#374151" />
            </div>
            <div className="flex">
              <div className={"h-4 bg-gray-200 rounded w-32"}></div>
            </div>
          </div>
          <div className={"h-8 bg-gray-200 rounded w-8"}></div>
        </div>
        <div className="h-full w-0.5 bg-gray-100"></div>

        {/* Phone */}
        <div className="flex justify-between gap-1 w-full items-center">
          <div className="flex flex-col">
            <div className="flex gap-1 items-center font-bold">
              <div>Téléphone</div>
              <WarningCircle wh={20} color="#374151" />
            </div>
            <div className="flex">
              <div className={"h-4 bg-gray-200 rounded w-32"}></div>
            </div>
          </div>
          <div className={"h-8 bg-gray-200 rounded w-8"}></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-4 px-4 py-2 border border-gray-100 rounded-lg bg-white">
      <div className="flex flex-col w-full">
        <div className="flex gap-1 items-center font-bold">
          <div>Mission</div>
          <WarningCircle wh={20} color="#374151" />
        </div>
        <div className="flex">{id}</div>
      </div>
      <div className="h-full w-0.5 bg-gray-100"></div>

      {/* Code pin */}
      <div className="flex justify-between gap-1 w-full items-center">
        <div className="flex flex-col">
          <div className="flex gap-1 items-center font-bold">
            <div>Code pin</div>
            <WarningCircle wh={20} color="#374151" />
          </div>
          <div className="flex">{userMission.tokenMission || "Non défini"}</div>
        </div>
        <Button color="ICON" size="ICON" onClick={editTempWorkerToken}>
          <Synchronisation wh={20} color="#374151" />
        </Button>
      </div>
      <div className="h-full w-0.5 bg-gray-100"></div>

      {/* Phone */}
      <div className="flex justify-between gap-1 w-full items-center">
        <div className="flex flex-col">
          <div className="flex gap-1 items-center font-bold">
            <div>Téléphone</div>
            <WarningCircle wh={20} color="#374151" />
          </div>
          <div className="flex">{userMission.phone || "Non défini"}</div>
        </div>
        <Button color="ICON" size="ICON" onClick={editTempWorkerPhone}>
          <Pencil wh={20} color="#374151" />
        </Button>
      </div>
    </div>
  );
};

export default MissionInfoBar;
