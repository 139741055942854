import { NavLink, useLocation } from "react-router-dom";
import { ArrowLeft } from "../../assets/icons";

const NavBarMission = ({ user, isWaiting }) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const dateSelectParam = params.get("dateSelect");
  const searchParam = params.get("search");
  const companySelectedParam = params.get("companySelected");
  const interimAgencySelectedParam = params.get("interimAgencySelected");
  const interimSectorSelectedParam = params.get("interimSectorSelected");
  const missionStatusListParam = params.get("missionStatusList");

  const paramsToPass = new URLSearchParams();

  if (dateSelectParam) paramsToPass.append("dateSelect", dateSelectParam);
  if (searchParam) paramsToPass.append("search", searchParam);
  if (companySelectedParam)
    paramsToPass.append("companySelected", companySelectedParam);
  if (interimAgencySelectedParam)
    paramsToPass.append("interimAgencySelected", interimAgencySelectedParam);
  if (interimSectorSelectedParam)
    paramsToPass.append("interimSectorSelected", interimSectorSelectedParam);
  if (missionStatusListParam)
    paramsToPass.append("missionStatusList", missionStatusListParam);

  const navLinkUrl = `/hourly-tracking?${paramsToPass.toString()}`;

  return (
    <nav className={"w-full flex justify-between items-center"}>
      <div className={"flex gap-4 items-center"}>
        <NavLink to={navLinkUrl} className={"p-3 border rounded-full"}>
          <ArrowLeft wh={24} color={"#374151"} />
        </NavLink>
        <div className={"flex flex-col"}>
          <div className={"text-2xl font-bold capitalize"}>
            {isWaiting ? (
              <div className={"h-6 bg-gray-200 rounded w-40"}></div>
            ) : (
              `${user?.firstname} ${user?.lastname}`
            )}
          </div>
          <div className={"text-gray-500 text-sm"}>
            {isWaiting ? (
              <div className={"h-4 bg-gray-200 rounded w-20 mt-2"}></div>
            ) : (
              `Matricule : ${user?.peopulseId}`
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBarMission;
