import React from "react";
import MissionDetailSidebar from "../components/mission/MissionDetailSidebar";
import MissionInfoBar from "../components/mission/MissionInfoBar";
import MissionWeekSelector from "../components/mission/MissionWeekSelector";
import NavBarMission from "../components/navbars/NavBarMission";
import PageContainer from "../components/pageContainer/PageContainer";
import useTitle from "../hooks/TitleManager";
import { useMissionData } from "../hooks/useMissionData";

const Mission = () => {
  useTitle({ title: "Mission" });
  const {
    isWaitingMission,
    isWaitingUserMission,
    missionDetail,
    missionByWeek,
    currentWeekIndex,
    canGoPrev,
    canGoNext,
    goPrevWeek,
    goNextWeek,
    getWeekLabel,
    editTempWorkerPhone,
    editTempWorkerToken,
    getTotalWorkedHours,
    getTotalNightHours,
    getTotalOvertimes,
    timeCut,
    todayRef,
    userMission,
    id,
  } = useMissionData();

  // Retrieve the list of missions for the current week
  const currentWeekMissions = missionByWeek[currentWeekIndex] || [];

  return (
    <PageContainer type="mission">
      <div className="flex flex-1 flex-col gap-6">
        {/* Mission navigation bar */}
        <NavBarMission user={userMission} isWaiting={isWaitingUserMission} />

        <div className="flex flex-1 gap-12">
          {/* Main column */}
          <div className="w-full max-w-[654px] flex flex-col gap-8">
            {/* Info bar (mission details / PIN code / phone) */}
            <MissionInfoBar
              id={id}
              isWaitingUserMission={isWaitingUserMission}
              userMission={userMission}
              editTempWorkerPhone={editTempWorkerPhone}
              editTempWorkerToken={editTempWorkerToken}
            />

            {/* List or skeleton loader for timesheets */}
            <MissionWeekSelector
              isWaitingMission={isWaitingMission}
              missionByWeek={missionByWeek}
              currentWeekIndex={currentWeekIndex}
              currentWeekMissions={currentWeekMissions}
              canGoPrev={canGoPrev}
              canGoNext={canGoNext}
              goPrevWeek={goPrevWeek}
              goNextWeek={goNextWeek}
              getWeekLabel={getWeekLabel}
              todayRef={todayRef}
              timeCut={timeCut}
            />
          </div>

          {/* Right column (detailed information) */}
          <MissionDetailSidebar
            missionDetail={missionDetail}
            currentWeekMissions={currentWeekMissions}
            getTotalWorkedHours={getTotalWorkedHours}
            getTotalNightHours={getTotalNightHours}
            getTotalOvertimes={getTotalOvertimes}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default Mission;
