import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useModal from "../components/modal/useModal";
import ApiDatabase from "../server";
import { dateShortText2 } from "../utils/Date";
import UseContext from "./useContext";

export function useMissionData() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateParam = queryParams.get("date");
  const autoSelectDoneRef = useRef(false);
  const prevDateParamRef = useRef(dateParam);

  const {
    slideOverDataReturn,
    setSlideOverDataReturn,
    setModalData,
    modalDataReturn,
    setModalDataReturn,
    timeCut,
    setTimeCut,
    mission,
    setMission,
    userMission,
    setUserMission,
  } = UseContext();

  const { toggle } = useModal();

  const [isWaitingMission, setIsWaitingMission] = useState(true);
  const [isWaitingUserMission, setIsWaitingUserMission] = useState(true);
  const [missionDetail, setMissionDetail] = useState({});
  const [missionByWeek, setMissionByWeek] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);

  const todayRef = useRef(null);

  // --- API call for mission
  useEffect(() => {
    if (!id) return;
    setIsWaitingMission(true);
    ApiDatabase.getMissionById(
      { id },
      (data) => {
        setMissionDetail(data.missionDetail);
        setMission(data.mission);
        setTimeCut(data.timeCut);
        setIsWaitingMission(false);
      },
      (err) => {
        console.log(err);
        setIsWaitingMission(false);
      }
    );
  }, [id, setMission, setTimeCut]);

  // --- API call for user mission
  useEffect(() => {
    if (!id) return;
    setIsWaitingUserMission(true);
    ApiDatabase.getUserMissionById(
      { id },
      (data) => {
        setUserMission(data.user);
        setIsWaitingUserMission(false);
      },
      (err) => {
        console.log(err);
        setIsWaitingUserMission(false);
      }
    );
  }, [id, setUserMission]);

  // --- Handle slideOver return (timesheet modification, etc.)
  useEffect(() => {
    if (slideOverDataReturn) {
      if (
        slideOverDataReturn.idMission &&
        slideOverDataReturn.date &&
        slideOverDataReturn.timeSheet?.length > 0
      ) {
        const missionIndex = mission.findIndex(
          (m) => m.date === slideOverDataReturn.date
        );
        if (slideOverDataReturn.idMission === id && missionIndex > -1) {
          const newMission = [...mission];
          if (slideOverDataReturn.idTimeSheet) {
            newMission[missionIndex].idTimeSheet =
              slideOverDataReturn.idTimeSheet;
          }
          newMission[missionIndex].markups = slideOverDataReturn.timeSheet;
          newMission[missionIndex].pause = slideOverDataReturn.pause;
          newMission[missionIndex].totalHours = slideOverDataReturn.totalHours;
          newMission[missionIndex]["totalHoursNight5%"] =
            slideOverDataReturn["totalHoursNight5%"];
          newMission[missionIndex]["totalHoursNight20%"] =
            slideOverDataReturn["totalHoursNight20%"];

          setMission(newMission);
        }
        setSlideOverDataReturn({});
      }
    }
  }, [slideOverDataReturn, setSlideOverDataReturn, mission, id, setMission]);

  // --- Edit temp worker phone
  const editTempWorkerPhone = () => {
    toggle("edit_temp_worker_phone");
    setModalData({
      idTempWorker: userMission.idTempWorker,
      phone: userMission.phone,
    });
  };

  // --- Edit temp worker token
  const editTempWorkerToken = () => {
    toggle("edit_temp_worker_token");
    setModalData({
      idTempWorker: userMission.idTempWorker,
    });
  };

  // --- Handle modal return (new phone/token info)
  useEffect(() => {
    if (modalDataReturn?.phone) {
      const userMissionChanges = {
        phone: modalDataReturn.phone,
      };
      if (modalDataReturn.tokenMission) {
        userMissionChanges.tokenMission = modalDataReturn.tokenMission;
      }
      setUserMission({
        ...userMission,
        ...userMissionChanges,
      });
      setModalDataReturn({});
    }
    if (modalDataReturn?.tokenMission) {
      setUserMission({
        ...userMission,
        tokenMission: modalDataReturn.tokenMission,
      });
      setModalDataReturn({});
    }
  }, [modalDataReturn, userMission, setModalDataReturn, setUserMission]);

  // --- Week management
  const getMonday = (date) => {
    const [year, month, day] = date.split("T")[0].split("-").map(Number);
    const d = new Date(year, month - 1, day);

    const dayOfWeek = d.getDay();
    const diff = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    d.setDate(d.getDate() - diff);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  const groupMissionsByWeeks = (missions) => {
    if (!missions?.length) return [];
    const sorted = [...missions].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    const weeksMap = {};
    sorted.forEach((m) => {
      const monday = getMonday(m.date).toISOString().split("T")[0];

      if (!weeksMap[monday]) {
        weeksMap[monday] = [];
      }
      weeksMap[monday].push(m);
    });
    return Object.values(weeksMap);
  };

  // --- Grouping by weeks
  useEffect(() => {
    if (mission && mission.length) {
      const grouped = groupMissionsByWeeks(mission);
      setMissionByWeek(grouped);
      setCurrentWeekIndex((prevIndex) => {
        // Si l'index actuel est encore dans les limites du nouveau tableau, on le garde
        return prevIndex < grouped.length ? prevIndex : 0;
      });
    } else {
      setMissionByWeek([]);
      setCurrentWeekIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mission]);

  // --- Auto-select week if `date` parameter is present
  useEffect(() => {
    if (dateParam && missionByWeek.length) {
      if (
        !autoSelectDoneRef.current ||
        prevDateParamRef.current !== dateParam
      ) {
        const targetIndex = missionByWeek.findIndex((week) =>
          week.some((m) => m.date === dateParam)
        );
        if (targetIndex !== -1) {
          setCurrentWeekIndex(targetIndex);
        }
        autoSelectDoneRef.current = true;
        prevDateParamRef.current = dateParam;
      }
    }
  }, [dateParam, missionByWeek]);

  const getWeekLabel = () => {
    if (!missionByWeek.length) return "";
    const currentWeekMissions = missionByWeek[currentWeekIndex] || [];
    if (currentWeekMissions.length === 0) return "";
    const sorted = [...currentWeekMissions].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    const firstDay = dateShortText2(new Date(sorted[0].date));
    const lastDay = dateShortText2(new Date(sorted[sorted.length - 1].date));
    if (sorted.length === 1) {
      return `Semaine du ${firstDay}`;
    }
    return `Semaine du ${firstDay} au ${lastDay}`;
  };

  const canGoPrev = currentWeekIndex > 0;
  const canGoNext = currentWeekIndex < missionByWeek.length - 1;

  const goPrevWeek = () => {
    if (canGoPrev) {
      setCurrentWeekIndex((prev) => prev - 1);
    }
  };

  const goNextWeek = () => {
    if (canGoNext) {
      setCurrentWeekIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (todayRef.current) {
      todayRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [mission?.date, dateParam]);

  // --- Hour calculations
  const getTotalWorkedHours = () => {
    if (!missionByWeek[currentWeekIndex]) return 0;
    return missionByWeek[currentWeekIndex].reduce((acc, item) => {
      const value = parseFloat(item.totalHours) || 0;
      return acc + value;
    }, 0);
  };

  const getTotalNightHours = () => {
    if (!missionByWeek[currentWeekIndex]) return 0;
    return missionByWeek[currentWeekIndex].reduce((acc, item) => {
      const value5 = parseFloat(item["totalHoursNight5%"]) || 0;
      const value20 = parseFloat(item["totalHoursNight20%"]) || 0;
      return acc + value5 + value20;
    }, 0);
  };

  const getTotalOvertimes = () => {
    if (!missionByWeek[currentWeekIndex]) return 0;
    return Math.max(getTotalWorkedHours() - 35, 0);
  };

  return {
    isWaitingMission,
    isWaitingUserMission,
    missionDetail,
    missionByWeek,
    currentWeekIndex,
    setCurrentWeekIndex,
    canGoPrev,
    canGoNext,
    goPrevWeek,
    goNextWeek,
    getWeekLabel,
    editTempWorkerPhone,
    editTempWorkerToken,
    getTotalWorkedHours,
    getTotalNightHours,
    getTotalOvertimes,
    timeCut,
    todayRef,
    userMission,
    id,
  };
}
