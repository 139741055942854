import NavbarSite from "../navbars/NavbarSite";
import SidebarSite from "../sidebars/SidebarSite";

const PageContainer = ({
  children,
  type,
  dateSelect,
  setDateSelect,
  dateList,
}) => {
  return (
    <div className="flex flex-col h-screen overflow-auto">
      <div className={"flex h-full"}>
        <SidebarSite />
        <div className={"flex flex-col w-full"}>
          <NavbarSite
            type={type}
            dateSelect={dateSelect}
            setDateSelect={setDateSelect}
            dateList={dateList}
          />
          <div
            className={"flex-1 px-8 py-6 overflow-auto page-container-scroll"}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContainer;
